nav {
    width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 8vh;
    background-color: hsl(0, 0%, 0%, 50%);
    backdrop-filter: saturate(190%) blur(10px);
    z-index: 1000;  
}
  
  .nav-container {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 1000;
  }

.logo {
  text-transform: uppercase;
  font-size: 1.5em;
  color: #A6DEE7;
  padding-left: 20px;
}

.logo:hover {
  transform: scale(1.1);
}

.navLinks {
  display: inline-flex;
  justify-content: space-around;
  list-style: none;
}

.navLinks a {
  color: #a8dadc;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1em;
  padding: 0em 2em;  
}

.navLinks a:hover {
  color:  #fde0a6;
}


.burger div {
  width: 25px;
  height: 2px;
  margin: 5px;
  background-color: #A6DEE7;
  transition: 0.5s ease;
}

.burger {
  display: none;
  margin: 10px;
}

.burger:hover div {
 background-color: #fde0a6;
}

@media screen and (max-width: 768px) {
  .burger {
    display: block;
    cursor: pointer;
  }

  .navLinks {
    display: flex;
    visibility: hidden;
    opacity: 0;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    right: 0;
    top: 8vh;
    height: 92vh;
    background-color: #282c34;
    flex-direction: column;
    align-items: center;
    width: 0;
    padding: 0;
    transition: opacity 0.5s ease-in, width 0.5s ease-in, visibility 1s ease-in;
  }

  .navLinks > * {
    display: none;
  }

  .navLinks-active a {
    font-size: 1.5em;
    padding: 0;
  }

  .navLinks-active {
    visibility: visible;
    width: 50%;
    opacity: 100;
    transition: opacity 0.5s ease-in, width 0.5s ease-in;
  }

  .navLinks-active > * {
    display: block;
  }

}



.navLinks-active li:nth-child(1) {
  animation: navLinkFades 1s 0s ease-in;
}

.navLinks-active li:nth-child(2) {
  animation: navLinkFades 1.25s 0s ease-in;
}

.navLinks-active li:nth-child(3) {
  animation: navLinkFades 1.5s 0s ease-in;
}

@keyframes navLinkFades {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 100;
    transform: translateX(0px);
  }
}

.burger-active .line1 {
  transform: rotate(-45deg) translate(-5px, 5px);
  transition: transform 0.5s ease-in;
}

.burger-active .line2 {
  opacity: 0;
  transition: transform 0.5s ease-in;
}

.burger-active .line3 {
  transform: rotate(45deg) translate(-5px, -5px);
  transition: transform 0.5s ease-in;
}