
.am-container {
  margin: 1em;
    display: flex;
    flex-wrap: wrap;
}

.am-text {
    flex: 1 1 350px;
    text-align: left;
}

.am-headshot {
  flex: 1 1 200px;
  
  align-content: center;
  margin: 1em;
}

.headshot {
  width: 100%;
  max-width: 375px;
}

.am-container p {
  font-size: 1.1em;
  margin: 1em;
}

.am-container a {
  color: #a8dadc;
  text-decoration: none;
  font-weight: bold;
}

.am-container a:hover {
  color:  #fde0a6;
}
