

.hero {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: -1;
    min-height: 110vh;
    background-color: #08060d;
    background-image: url(hero-boston.JPG);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-text {
    font-family: 'Fira Code', 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
    position: relative;
    top: 50px;
    border-radius: 8vw;
    padding: 2em;
    backdrop-filter: blur(2px);
    margin: 0 auto;
}

/* .hero-text h1 {
    font-size: 250%;
} */