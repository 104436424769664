* {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  position: absolute;
  font-family: 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
  font-size: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  z-index: -2;
  background-color: #001531;
  background-image: url('./Background/whitegrit.png'), url('./Background/blackgrit.png');
  background-size: 500px, 1000px;
  background-repeat: repeat, no-repeat;
  color: #f1faee;
}

h1 {
  font-size: 2.5em;
  color: #d62b1f;
  font-family: 'Fira Code', 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
}

h2 {
  color: #d62b1f;
  font-size: 2em;
  font-family: 'Fira Code', 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
}

h3 {
  font-size: 1.5em;
  font-family: 'Fira Code', 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
}

section {
  margin: 0;
  padding: 2.5em 0;
}

p {
  line-height: 1.5;
}

