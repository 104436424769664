* {
    box-sizing: border-box;
}

.portfolioList-container-col {
    display: flex;
    flex-direction: column;
}

.portfolioList-container-row {
    display: flex;
    flex-flow: wrap;
}

.portfolioItem {
    display: flex;
    flex-direction: column;
    flex: 1 1 350px;
    margin: 2em 2em 2em 2em;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    background-color: #525252;
    overflow: hidden;
}

.portfolioItem-image-container {
    position: relative; 
}

.portfolioItem-role {
    position: absolute;
    opacity: 0.5;
    top: 35%;
    left: 50%;
    transform: translate(-50%) rotate(-45deg);
}

 .portfolioItem-contributor {
    background-color: #2c0a00;
}

 .portfolioItem-image {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}

 .portfolioItem-toolsList li {
  list-style: none;
  color: #08060d;
  background-color: #f1faee;
  border: 2px solid #08060d;
  border-radius: 5px;
  margin: 0.5em 0.5em;
  padding: 0.25em 0.5em;
  font-weight: bold;
}

.portfolioItem-toolsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0.5em;
}

.portfolioItem-title {
    margin: 0.25em;
}

.portfolioItem-toolsList div {
    margin: 0.25em;
}

.portfolioItem-info {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.portfolioItem-description p {
    margin: 1em 1em;
    text-align: left;
}

.portfolioItem-description {
    flex-grow: 1;
}

.portfolioItem-links-container a {
    text-decoration: none;
    margin: 0.5em;
    padding: 0.25em 0.5em;
    font-weight: bold;
    font-size: 1.1em;
}

.portfolioItem-links-container {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0;
}

.portfolioItem-link-primary {
    color: #08060d;
    background-color: #a8dadc;
}

.portfolioItem-link-primary:hover {
    background-color:#fde0a6;
}

.portfolioItem-link-secondary {
    color:  #a8dadc;
}

.portfolioItem-link-secondary:hover {
    color:  #fde0a6;
}
