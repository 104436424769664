.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    font-weight: 400;
}

.contactForm div {
    width: calc(100% - 4em);
    max-width: 500px;
    margin: 1em 2em;
}

.contactForm input {
    width: 100%;
    border-radius: 3px;
    padding: .375rem .75rem;
    font-size: 1em;
    line-height: 1.5;
    color: #495057;
    font-size: 1em;
    font-family: 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
    font-weight: 400;
    margin-top: 10px;
}

.contactForm label {
    display: flex;
}

.contactForm textarea {
    width: 100%;
    border-radius: 3px;
    padding: .375rem .75rem;
    font-size: 1em;
    font-family: 'Montserrat', 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
    font-weight: 400;
    margin-top: 10px;
}

.contactForm button {
    padding: 0.3em 0.6em;
    font-size: 1.1em;
    color: #f1faee;
    border-radius: 10px;
    background-color: inherit;
    border: 1px solid #f1faee;
    /* box-shadow: 3px 3px 3px rgba(0, 0, 0, 1); */
    backdrop-filter: sepia(30%);
    transition-property: all 0.3s;
}

.contactForm button:hover {
    background-color: #f1faee; 
    color: #08060d;
}

.contactForm button:active {
transform: translateY(1px);
box-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}


